.barchart-wrap {
  box-shadow: #1F1F1F;
  border-radius: 10px;
  background-color: $white-color;

  .barchart-header {
    display: flex;
    padding: 15px 15px 0 15px;
    justify-content: space-between;
    align-items: center;

    .barchart-left {
      h2 {
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
      }

      p {
        margin: 0;
        font-size: 14px;
        padding-top: 10px;
        color: $gray-color;
        font-weight: 200 !important;
      }
    }

    .barchart-right {
      display: flex;
      justify-content: flex-end; 
      
      .year-dropdown {
        background-color:#d1e5f7 ;
        font-size: 14px;
        border-radius: 8px;
        border: none;
      }
    }
  }

  .barchart-table {
    width: 100%;
    height: 300px;
  }
}