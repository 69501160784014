.main-container{
  .content-area {
    .summary-wrap {
      .payment-info {
        background-color: $white-color;
        border-radius: 8px;
        padding: 20px;

        display: flex;
        flex-direction: column;
        gap: 15px;

        h2 {
          margin: 0;
          font-size: 16px;
          color: $black-color;
        }

        .single-row {
          display: grid;
          grid-template-columns: 47% 47%;
          justify-content: space-between;

          .input-symbol {
            left: 17px;
            top: 49.5px;
          }

          .input-inner-symbol {
            input {
              padding: 7px 25px !important;
            }
          }
        }
      }
    }
  }
}