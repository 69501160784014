.confirm-wrap {
  margin: 10px 0;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 25px;

  .confirm-content {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      color: $black-color;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: $black-color;
      line-height: 25px;
    }
  }

  .confirm-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}