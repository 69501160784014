.rental-devices-form {
    width: 70vw;
    max-height: 70vh;
    overflow-y: scroll;

    .rental-device-row {
        display: grid;
        grid-template-columns: 22% 22% 22% 20% 10%;
        gap: 10px;
        align-items: center;
        padding: 20px 5px;
        border-bottom: solid 0.5px #ccc;

        .input-inner-symbol {
            input {
                padding: 8px 37px !important;
            }

            .input-symbol {
                top: 71%;
                transform: translate(70%, -50%);
            }
        }

        .add-button {
            margin-top: 25px;
            cursor: pointer;
            color: $success-text;
            margin-top: 30px;
            font-size: 13px;
        }

        .delete-button {
            margin-top: 25px;
            cursor: pointer;
            color: $danger-text;
            margin-top: 30px;
            font-size: 13px;
            width: 10px;
        }
    }
}