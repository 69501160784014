.login-wrap {
  display: flex;

  .login-banner {
    height: 100vh;
    width: 50%;
    background-color: $success-button-background-color;

    display: flex;
    align-items: center;
    justify-content: center;

    .login-image {
      width: 40%;
      height: 100%;
      object-fit: contain;
    }
  }

  .login-form-wrap {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-form-container {
      width: 65%;

      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 10px;

      .login-form-header {
        h1 {
          margin: 0;
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          color: $success-text;
        }

        h4 {
          margin: 5px 0 0;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
        }

        p {
          margin: 10px 0 0;
          opacity: var(--opacity, 1);

          font-size: 14px;
          font-weight: 600;
          color: $danger-text;
        }

        .loginDesc {
          line-height: 24px;
          font-weight: 400;
          margin: 0px;
          font-size: 16px;
          color: #1f1f1f;
        }

        .reset-password-message {
          margin: 10px 0;
          width: 100%;
          padding: 15px 10px;

          display: flex;
          justify-content: center;
          gap: 15px;

          background-color: $success-table-header-background-color;
          border: 0.7px solid $success--green-text;
          border-radius: 5px;

          p {
            margin: 0;
            font-size: 14px;
            color: $success--green-text;
          }

          span {
            &:last-child {
              cursor: pointer;
            }
          }
        }
      }

      .login-form {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 5px;

        .forget-password {
          margin: 0 0 10px;
          align-self: flex-end;

          a {
            font-size: 16px;
            font-weight: 500;
            color: $success-text;
          }
        }

        .resend {
          display: flex;
          gap: 1%;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          span {
            color: $success-text;
            cursor: pointer;
          }
        }

        .btn {
          button {
            width: 100%;
            margin-top: 30px;
          }
        }
      }
    }
  }

  .loginButton {
    width: 100%;
    border-top: 1px solid $gray-color;
    text-align: center;
    padding: 20px 0;

    a {
      width: 100%;
      font-size: 14px;
      color: $black-color;
      font-weight: 500;
    }
  }

  .language-wrap-absolute {
    position: absolute;
    top: 2%;
    right: 2%;

    display: flex;
    align-items: center;
  }

  .language-wrap-absolute-ar {
    position: absolute;
    top: 2%;
    left: 2%;

    display: flex;
    align-items: center;
  }
}