.top-content {
    background-color: $white-color;
    padding: 10px 25px;
    border-radius: 8px;

    .top-main {
        background-color: #FAFAFA;
        padding: 0px 21px;
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;

        .top-label {
            font-size: 16px;
            font-weight: 600;
            color: #1F1F1F;

            .title-text{
                gap: 15px;
                display: flex;

                span {
                    cursor: pointer;
                }

                img {
                    width: 18px;
                    transform: rotate(270deg);
                }
            }
        }

        .top-button {
            .btn button {
                font-size: 14px;
            }
        }
    }
}