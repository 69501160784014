.side-bar {
    width: 100%;
    height: 100%;

    .sidebar-menu {
        border-top: solid 1px $border-color;
        // height: 610px;

        overflow-y: scroll;
        height: 92vh;

    }
}

.sidebar-menu-session {
    padding: 16px 24px;
    border-bottom: solid 0.3px $border-color;

    .menu-header {
        font-weight: 500;
        font-size: 15px;
        color: $gray-color;
        padding-left: 10px;
    }

    .menu-list {
        display: flex;
        padding: 18px 0px 0px 0px;
        gap: 4px;
        flex-direction: column;

        .menu {
            font-weight: 400;
            font-size: 15px;
            padding: 14px 9px;

            a {
                color: $gray-color;
                width: 100%;
                display: flex;
                gap: 16px;
            }

            .menu-image {
                width: 19px;
                height: 19px;
            }

            &.active {
                background: #F7FBFE;

                a,
                .sub-menu {
                    color: $success-text;
                }
            }
        }
    }

    .sub-menu {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        span {
            &:first-child {
                display: flex;
            }
        }
    }
}

@media only screen and (max-width:1000px) and (min-width:760px) {
    .sidebar-menu-session {
        .menu-header {
            font-size: 13px;
        }

        .menu-list {
            .menu {
                font-size: 14px;
            }
        }

        .sub-menu {
            span {
                &:first-child {
                    width: 18px;
                }
            }
        }
    }
}

@media only screen and (max-width:760px) and (min-width:450px) {
    .side-bar {
        .sidebar-menu {
            display: none;
        }
    }

    .sidebar-menu-session {
        .menu-header {
            font-size: 13px;
        }

        .menu-list {
            .menu {
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width:450px) {
    .side-bar {
        .sidebar-menu {
            display: none;
        }
    }

    .sidebar-menu-session {
        .menu-header {
            font-size: 12px;
        }

        .menu-list {
            .menu {
                font-size: 12px;
            }
        }
    }
}