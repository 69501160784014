.order-summary-wrap {
  max-height: 55vh;
  overflow: scroll;

  margin: 20px 0 0;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 5px 0px 0px 25px !important;
    font-size: 14px;
    font-weight: 500;
    color: $black-color;

    span {
      font-weight: 500;
    }
  }

  .order-summary-container {
    padding: 15px 25px 20px;
    border-bottom: 1px solid $border-color;


    .order-summary {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .each-detail {
        display: flex;
        flex-direction: column;
        gap: 7px;

        h3 {
          margin: 0;
          font-size: 13px;
          font-weight: 500;
          color: $label-color;
        }

        p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          color: $black-color;
        }
      }

      .remove-icon {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        span {
          &:last-child {
            font-size: 12px;
            font-weight: 500;
            color: $danger-text;
          }
        }
      }
    }
  }
}