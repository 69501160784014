.new-content {
    width: 600px;

    .content-buttons {
        padding: 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .buttons-list {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            .or-break {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .hr-line {
                width: 117px;
                border-top: solid 0.5px $gray-color;
            }

            .hr-text {
                color: $gray-color;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

@media only screen and (max-width: 676px) {
    .new-content {
        width: 100%;
    }
}