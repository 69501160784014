.company-form {
    padding: 0.7rem;
    display: grid;
    grid-template-columns: 25% auto;
    gap: 1.3rem;

    .profile-upload {
        height: max-content;
        padding: 70px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: 8px;

        .upload-click {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            cursor: pointer;

            img {
                width: 125px;
                height: 125px;
                object-fit: cover;
                border-radius: 50%;
            }

            p {
                margin: 0;
                font-size: 16px;
                color: $gray-color;
                text-align: center;

                span {
                    color: #1A5EEC;
                    text-decoration: underline;
                }
            }
        }
    }

    .details-upload {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: 8px;

        .company-info,
        .user-info {
            padding: 30px 30px 25px;
            display: flex;
            flex-direction: column;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            border-radius: 8px;
            gap: 1rem;

            h2 {
                margin: 0;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 600;
                color: $label-color;
            }

            .form-field {
                width: 100%;
                display: grid;
                grid-template-columns: 46% 46%;
                justify-content: space-between;

                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }

    }
}