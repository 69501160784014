.count-wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;

  max-height: 130px;
  overflow-x: scroll;

  .each-count {
    box-shadow: #1F1F1F;
    border-radius: 10px;
    background-color: $white-color;

    .count-top {
      padding: 15px 15px 6px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .count-header {

        p {
          margin: 0;
          padding: 0;
          color: $gray-color;
          text-transform: capitalize;
          font-weight: 100;
          font-size: 13px;
        }

        h1 {
          padding-top: 10px;
          font-size: 24px;
          font-weight: 300 !important;
        }
      }

      .count-img {
        width: 45px;
        height: auto;
        object-fit: contain;
      }
    }

    .count-bottom {
      padding: 5px 25px 5px 15px;
      display: flex;
      align-items: center;
      gap: 5px;

      span {
        margin: 0;
        font-size: 13px;
        font-weight: 100 !important;
        color: var(--percentage-color, $black-color);
        padding-bottom: 10px;

        .infinity-symbol {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width:760px) and (min-width:450px) {
  .count-wrap {
    display: flex;
    flex-direction: row;
    max-height: 190px;


    .each-count {
      height: 80%;
    }
  }
}

@media only screen and (max-width:450px) {
  .count-wrap {
    display: flex;
    flex-direction: row;
    max-height: 190px;


    .each-count {
      height: 80%;
    }
  }
}