$white-color: #fff;
$black-color: #1F1F1F;
$green-color: #198431;
$gray-color: #8e8e8e;
$gray-light-color: #ccc;
$gray-hover-color: #FAFAFA;
$gray-light: #CACACA;
$yellow-color: #F19702;
$blue-color: #1A5EEC;
$label-color: #4b4b4b;
$dark-gray-color: #5c5858;
$text-gray-color: #717880;
$backend-blue: #f1f5f8;
$border-color: #CACACA;
$light-border-color: #e2dcdc;
$pending-color: #F37D10;

// Success
$success-button-background-color: #1C75BC;
$success-text: #1C75BC;
$success--green-text: #30A84B;
$success-button-hover-background-color: #0066ba;
$success-table-header-color: #198431;
$success-table-header-background-color: #F7FEF8;

// Danger
$danger-button-background-color: #E8212E;
$danger-button-hover-background-color: #b50511;
$danger-text: #E8212E;
$danger-background: #FEF7F7;

// Default
$default-button-background-color: #ffffff;
$default-button-hover-background-color: rgb(223, 220, 220);

// Info
$info-button-background-color: #1A5EEC;
$info-button-hover-background-color: #023dba;
$info-text-hover-background-color: #f4f4f5;

// Warning
$warning-button-background-light-color: #FEFBF7;

// error
$error-border-color: #E8212E;