.input {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    .label {
        font-weight: 400;
        font-size: 1rem;
        line-height: 2.4375em;
        color: #1F1F1F;
        display: flex;
        align-items: center;
        gap: 8px;

        .image {
            width: 19px;
            height: 19px;
        }
    }

    .image-banner {
        width: 100%;
        height: 225px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .upload-image-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            border: 1px $gray-color solid;
            border-style: dashed;
            height: 100%;
            background: #FAFAFA;
            border-radius: 20px;
            flex-direction: column;
            cursor: pointer;

            img {
                width: 5%;
                height: 17%;
            }

            label {
                color: $gray-color;
                font-size: 18px;
                font-weight: 500;
            }
        }

        img {
            width: auto;
            height: 100%;
        }

        .remove-button {
            color: #E8212E;
            font-size: 16px;
            font-weight: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            img {
                width: 19px;
                height: 19px;
            }
        }
    }

    .input-box {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 2;
        letter-spacing: 0.00938em;
        width: 100%;
        padding: 7px 9px;
        border-radius: 3px;
        border: 0.75px solid $border-color;
    }

    .input-box-error {
        border: 0.5px solid $error-border-color;
    }

    .error-message {
        margin: 5px 0 0;
        color: $danger-text;
        font-size: 12px;
        font-weight: 500;
    }

    .password-view-icon {
        position: absolute;
        cursor: pointer;
        right: 3%;
        top: 53px;
        width: 23px;
    }

    .ar {
        position: absolute;
        cursor: pointer;
        left: 3%;
        top: 52px;
        width: 23px;
    }

    .input-group {
        width: 100%;
        border: 0.75px solid $border-color;
        padding: 7px 9px;
        border-radius: 3px;
        display: grid;
        grid-template-columns: auto 50px;

        .input-group-field {
            width: 100%;
            border: none;
            padding: 1px 0px;
            flex: 1;

            &:focus {
                border: none;
                outline: none;
            }
        }
    }

    .input-group:focus-within {
        border: 2px solid $black-color;
    }
}

.input-inner-icon {
    display: flex;
    gap: 10px;
    border: 1px solid $border-color;
    padding: 9px 11px;
    border-radius: 3px;

    .image {
        width: 17px;
    }

    .input-box {
        font-size: 13px;
        border: none;
        box-shadow: none;
    }

    .input-box:focus,
    .input-box:active {
        outline: none;
    }
}

.input-black {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    .label {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 1.4375em;
        color: $label-color;
        display: flex;
        align-items: center;
        gap: 2px;

        .image {
            width: 19px;
            height: 19px;
        }
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 2.8px;
        border: 1px solid $label-color;
    }

    .image-banner {
        position: relative;
        width: 100%;
        height: 225px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .upload-image-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            border: 1px solid $border-color;
            border-style: dashed;
            height: 100%;
            background: #FAFAFA;
            border-radius: 3px;
            flex-direction: column;
            cursor: pointer;

            img {
                height: 17%;
                object-fit: contain;
            }

            label {
                color: $gray-color;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;

                span {
                    color: $blue-color;
                    text-decoration: underline;
                }
            }
        }

        .content-image {
            object-fit: contain;
            flex: 9;
            height: 100%;
            border: 1.5px solid #0000004e;
            border-radius: 3px;
        }

        .remove-button {
            position: absolute;
            top: 12px;
            right: 12px;
            color: #E8212E;
            font-size: 16px;
            font-weight: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            img {
                width: 19px;
                height: 19px;
            }
        }
    }

    .date-input {
        padding-top: 0px !important;
        width: 100% !important;
        min-width: 0 !important;
    }

    .input-box {
        font-weight: 400;
        font-size: 14px;
        line-height: 2;
        letter-spacing: 0.00938em;
        width: 100%;
        padding: 7px 9px;
        border-radius: 3px;
        border: 0.75px solid $border-color;

        .input-hide {
            border: none;
        }

        .input-hide::placeholder {
            background-color: $gray-hover-color;
            color: $gray-color;
            letter-spacing: 0.00938em;
        }

        .input-hide:focus,
        .input-hide:active {
            outline: none;
        }

        .css-pl8gqh-MuiStack-root {
            display: flex;
            gap: 10px 0px !important;
            flex-wrap: wrap !important;
        }
    }

    .input-box-error {
        border: 0.5px solid $error-border-color;
    }

    .error-message {
        margin: 5px 0 0;
        color: $danger-text;
        font-size: 12px;
        font-weight: 500;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .input-box::placeholder {
        color: $gray-color;
    }

    .password-view-icon {
        position: absolute;
        z-index: 999;
        cursor: pointer;
        right: 15px;
        top: 41px;
        width: 20px;
    }

    .images-list {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: center;

        .image-item {
            width: 50px;
            height: 50px;

            .image-content {
                width: 100%;
                height: 100%;

                &:hover {
                    .remove-button {
                        display: block;
                    }
                }

                .remove-button {
                    display: none;
                    background: rgba(31, 31, 31, 0.436);
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    top: 241px;
                    z-index: 9;
                    cursor: pointer;

                    img {
                        width: 50%;
                        height: 50%;
                        float: right;
                    }
                }
            }
        }
    }
}

.input-increase-decrease {
    position: relative;

    .input-wrap {
        width: 100%;
        overflow: hidden;
        display: flex;

        .input-operator {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $border-color;
            user-select: none;
            cursor: pointer;

            &:nth-child(1) {
                border-radius: 3px 0px 0px 3px;
                border-right: none;
            }

            &:nth-child(3) {
                border-radius: 0px 3px 3px 0px;
                border-left: none;
            }
        }

        .input-box-symbol {
            border-radius: 0px;
        }

        input {
            text-align: center;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input:focus {
            outline: none;
        }
    }
}

.input-inner-symbol {
    position: relative;

    .input-symbol {
        margin: 0;
        color: $gray-color;
        position: absolute;
        top: 47%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    .input-box-symbol {
        min-width: 50px;
        padding: 0 25px;
    }

    .input-box-symbol::-webkit-outer-spin-button,
    .input-box-symbol::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    .label {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 1.4375em;
        color: $label-color;
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .input-group-box {
        display: flex;
        border: 0.75px solid $border-color;
        border-radius: 3px;
        align-items: center;
        height: 44px;

        .input-box-input {
            border: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 2;
            letter-spacing: 0.00938em;
            width: 100%;
            padding: 0px 9px;

            &:focus,
            &:active {
                outline: none;
            }
        }

        .input-box-input::-webkit-outer-spin-button,
        .input-box-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .input-tag {
            padding: 10px;
            border-left: 0.75px solid $border-color;
            color: $gray-color;
        }
    }

    .input-group-box:focus-within {
        border: 2px solid $black-color;
    }
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px !important;
}

.input-box::placeholder {
    color: $gray-color;
}

.css-ahj2mt-MuiTypography-root {
    font-size: 13px !important;
}
.switch-toggle-button {
    display: flex;
    align-items: center;
    .disable-switch,
    .switch
    {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 23px;
  
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
  
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: .4s;
            border-radius: 34px;
  
            &:before {
                position: absolute;
                content: "";
                height: 17px;
                width: 17px;
                left: 2px;
                bottom: 3px;
                background-color: white;
                transition: .4s;
                border-radius: 50%;
            }
        }
  
        input:checked+.slider {
            background-color: #2196F3;
        }
  
        input:checked+.slider:before {
            transform: translateX(26px);
        }
      
        &.round {
            .slider {
                border-radius: 34px;
  
                &:before {
                    border-radius: 50%;
                }
            }
         } 
    }
  }
