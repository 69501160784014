.license-summary-wrap {
    max-height: 55vh;
    overflow: scroll;
  
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
  
    h2 {
      margin: 5px 0px 0px 25px !important;
      font-size: 14px;
      font-weight: 500;
      color: $black-color;
  
      span {
        font-weight: 500;
      }
    }
  
    .license-summary-container {
      padding: 15px 25px 20px;
      border-bottom: 1px solid $border-color;
  
  
      .license-summary {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // align-items: center;
  
        .license-content {
            display: grid;
            grid-template-columns: 350px auto;
        
            h3 {
              // margin: 0;
              font-size: 14px;
              font-weight: 500;
              color: $black-color;
        
              span {
                display: flex;
                justify-content: space-evenly;
                color: $gray-color;
              }
            }
        
            p {
              // margin: 0;
              font-size: 14px;
              font-weight: 600;
              color: $black-color;
            }
          }
  
        .remove-icon {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
  
          span {
            &:last-child {
              font-size: 12px;
              font-weight: 500;
              color: $danger-text;
            }
          }
        }
      }
    }
  }

  .discount_amount {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-black {
      width: 35%;
    }
  }
  .license-summary-wrap-content {
    max-height: 55vh;
    // overflow: scroll;
  
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
  
    h2 {
      margin: 5px 0px 0px 25px !important;
      font-size: 14px;
      font-weight: 500;
      color: $black-color;
  
      span {
        font-weight: 500;
      }
    }
  .license-summary-containers {

    .license-summary {
      display: flex;
      flex-direction: column;

      .license-content-summary {
          display: grid;
          grid-template-columns: 220px auto;
      
          .single-row-content {
            display: flex;
            gap: 10px;
          }
      
          p {
            // margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: $black-color;
          }
        }
    }
  }
}