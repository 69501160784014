.header {
    height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    padding: 0px 35px 0px 30px;

    // box-shadow: 10px 0px 9px 1px rgba(0, 0, 0, 0.1);
    .hamburger-icon {
        display: none;
    }

    .datetime {
        display: flex;
        flex-direction: column;
        color: #1F1F1F;
    }

    .right-navbar {
        display: flex;
        gap: 40px;
        align-items: center;

        .language-wrap {
            display: flex;
            align-items: center;
            gap: 0;

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
            }
        }

        .notification-icon {
            width: 24px;
            height: 24px;
        }

        .profile-button {
            display: flex;
            gap: 10px;
            align-items: center;

            .avatar-image {
                width: 35px;
                height: 35px;
                border-radius: 50px;
                overflow: hidden;
            }

            .user-name {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .name {
                    font-size: 14px;
                    font-weight: 500;
                    color: #1F1F1F;
                }

                .role {
                    font-size: 14px;
                    font-weight: 400;
                    color: $gray-color;
                }
            }

            .dropdown-arrow-icon {
                width: 19px;
                height: 19px;
                border-radius: 50px;
                overflow: hidden;
            }
        }
    }
}

.drawer-header {
    display: flex;
    justify-content: end;
}

@media only screen and (max-width:760px) and (min-width:450px) {
    .header {
        .hamburger-icon {
            display: flex;
            gap: 45%;

            .header-logo {
                display: flex;
                width: 27%;
                height: 42%;
                padding-top: 8px;
            }
        }

        .right-navbar {
            .language-wrap p {
                display: none;
            }
        }

        .drawer-header {
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media only screen and (max-width:450px) {
    .header {
        padding: 0px 19px 0px 16px;

        .hamburger-icon {
            display: flex;
            gap: 28%;

            .header-logo {
                display: flex;
                width: 162px;
                height: 42%;
                padding-top: 8px;
            }
        }

        .right-navbar {
            .language-wrap p {
                display: none;
            }

            .profile-button {
                .avatar-image {
                    display: none;
                }

                .user-name {
                    display: none;
                }
            }
        }

    }
}