.btn {
    width: 100%;

    .success-button,
    .danger-button,
    .default-button,
    .info-button,
    .back-button {
        // min-height: 45px;
        // min-width: 175px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        border: 0px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        padding: 6px 16px;
        border-radius: 3px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        color: $white-color;
        // width: 100%;
        cursor: pointer;
    }

    button:disabled,
    button[disabled] {
        background-color: $gray-color;
        color: #ffffff;
        border: solid 1px $gray-color;

        &:hover {
            background-color: $gray-color;
            border-color: $gray-color;
        }
    }

    button:active {
        transform: scale(0.98);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .success-button {
        min-width: 134px;
        background-color: $success-button-background-color;
        border: solid 1px $success-button-background-color;

        &:hover {
            background-color: $success-button-hover-background-color;
            border: solid 1px $success-button-hover-background-color;
        }
    }
    .btn-sx {
        min-width: 49px;
    }

    .info-button {
        background-color: $info-button-background-color;
        border: none;

        &:hover {
            background-color: $info-button-hover-background-color;
        }
    }

    .danger-button {
        background-color: $danger-button-background-color;
        border: solid 1px $danger-button-background-color;

        &:hover {
            background-color: $danger-button-hover-background-color;
            border: solid 1px $danger-button-hover-background-color;
        }
    }

    .export-button {
        background-color: transparent;
        padding: 6px 12px;
        border: 0.25px solid $border-color;
        border-radius: 20px;
    }

    .default-button {
        border: solid 1px var(--button-color, $black-color);
        color: var(--font-color, '#1F1F1F');
        background-color: var(--button-color, 'transparent');

        &:hover {
            background-color: var(--button-hover-color, '#0000001e');
        }
    }

    .button-default-outline {
        color: #1F1F1F;
        background-color: $default-button-background-color;
        border: none;
    }

    .button-outline-success {
        border: solid 1px $success-button-background-color;
        padding: 20px;

        &:hover {
            background-color: $success-table-header-background-color;
            color: $success-text;
            border: solid 1px $success-button-background-color;
        }
    }

    .button-outline-success-1 {
        border: solid 1px $success-button-background-color;

        &:hover {
            background-color: $success-table-header-background-color;
            color: $success-text;
            border: solid 1px $success-button-background-color;
        }
    }

    .loading-btn:disabled {
        background-color: $success-button-background-color;

        &:hover {
            background-color: $success-button-hover-background-color;
        }
    }

    .loading {
        display: flex;
        gap: 5px;

        .dot {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #FFFFFF4e;
        }

        .dot:nth-last-child(1) {
            animation: jumpingAnimation 1.2s 0.6s linear infinite;
        }

        .dot:nth-last-child(2) {
            animation: jumpingAnimation 1.2s 0.3s linear infinite;
        }

        .dot:nth-last-child(3) {
            animation: jumpingAnimation 1.2s 0s linear infinite;
        }


        @keyframes jumpingAnimation {
            0% {
                transform: translate(0, 0);
            }

            16% {
                transform: translate(0, -12px);
                background-color: #FFFFFF;
            }

            33% {
                transform: translate(0, 0);
            }

            100% {
                transform: translate(0, 0);
                background-color: #FFFFFF4e;
            }
        }
    }
}