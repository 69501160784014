.content-area{
    .my-content{
        .my-content-area{
            .subscription-menu{
                margin: 3% 3% 0;
                border-bottom: 0.74px solid #cacaca;

                display: flex;
                gap: 7%;

                .each-menu{
                    margin-bottom: -1.5px;
                    padding: 0 0 8px;
                    font-size: 16px;
                    font-weight: 500;
                    color: $gray-color;
                    cursor: pointer;
                    text-transform: capitalize;
                }

                .menu-active{
                    color: $success-text;
                    border-bottom: 2px solid $success-text;
                }
            }
        }
    }
}