.content-area {
    .subscription-form {
        height: 75vh;
        overflow-y: scroll;

        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h1 {
            font-size: 16px;
            color: $black-color;
        }

        .single-row {
            display: grid;
            grid-template-columns: 47% 47%;
            justify-content: space-between;

            .error-message {
                display: flex;
                align-items: center;
                padding-top: 10px;
                height: 10px;
                color: $danger-text;
                font-size: 12px;
            }

            .input-with-price {
                display: grid;
                grid-template-columns: auto max-content;
                gap: 20px;
                align-items: center;

                p {
                    margin: 30px 0 0 0;
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}