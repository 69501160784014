.my-content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .tab-bar {
        padding: 0px 40px 0px 20px;
        display: flex;
        justify-content: space-between;

        .tab-button {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .tab-bar-button {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            .tab-button {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            .tab-button-text {
                cursor: pointer;
            }
        }

        .tab-bar-list {
            display: flex;
            gap: 112px;

            .menus {
                color: $gray-color;
                font-weight: 400;
                font-size: 16px;
                padding-bottom: 8px;
                cursor: pointer;

                &.active {
                    color: #30A84B;
                    border-bottom: #30A84B 2px solid;
                    border-image: linear-gradient(to right, #fff 0%, #fff 20%, #30A84B 20%, #30A84B 80%, #fff 80%, #fff 100%) 100% 1;
                    // border-image: linear-gradient(to right, #30A84B 80%, transparent 50%) 100% 1;

                }
            }
        }
    }

    .my-content-form {
        display: flex;
        gap: 100px;
        padding: 20px 0px 30px 40px;

        .input-black {
            width: 30%;
        }

        .profile-part {
            flex: 3;

            .upload-profile {
                display: flex;
                flex-direction: column;
                align-items: center;

                .img {
                    width: 203px;
                    height: 203px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .btn>button {
                color: #1A5EEC;
            }
        }

        .form-path {
            display: flex;
            flex: 4;
            gap: 16px;
            flex-direction: column;

            .input-black {
                width: 80%;
            }

            .form-button-group {
                display: flex;
                justify-content: flex-end;
                gap: 30px;
                padding: 30px 0px;
                width: 80%;


                .form-button {
                    width: 150px;
                }
            }
        }
    }

    .form-button-group {
        display: flex;
        justify-content: flex-end;
        gap: 30px;
        padding: 5px 20px;

        // .form-button {
        //     width: 140px;
        // }
    }

    .my-content-area {
        thead td {
            position: sticky;
            /* make the table heads sticky */
            top: -1px;
        }

        .form-button-group {
            display: flex;
            justify-content: flex-end;
            gap: 30px;
            padding: 30px;


            // .form-button {
            //     width: 140px;
            // }
        }

        .signage-form {
            padding: 25px 35px;
            display: flex;
            gap: 20px;
            padding: 20px;
        }

        .my-content-permissions-list {
            padding: 5px 40px;

            h3 {
                font-size: 24px;
                color: #1F1F1F;
                font-weight: 500;
            }

            .permission-list {
                .permission-lis-session {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }
      
        .location-form {
            padding: 20px;
            display: grid;
            gap: 20px 80px;

            .input-text {}

            .input-text-area {
                grid-column: 1 / span 2;
            }
        }

        .schedule-form {
            display: grid;
            padding: 20px;
            gap: 30px 60px;

            .form-field {
                width: 100%;
                display: flex;
                gap: 20px;

                .input-black {
                    .input-box {
                        color: $gray-color;
                    }
                }
            }

            .form-select-field {
                grid-column: 1/ span 2;
            }

            .form-field-button {
                width: 26%;
                height: 38px;

                .btn {
                    button {
                        padding: 5px 15px;
                        font-size: 13px;
                    }

                }
            }
        }

        .grid-list {
            gap: 30px 42px;
        }

        .schedule-details {
            padding: 0px 20px 20px 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .schedule-details-list {
                gap: 20px;
                display: flex;
                padding: 0px 0px 10px 0px;

                .image {
                    width: 24px;
                    height: 24px;

                    img {
                        width: 100%;
                    }
                }
            }

            .contents-view {
                display: flex;
                width: 100%;
                justify-content: space-between;

                .contents-model {
                    padding: 20px;
                    border: solid 1px $black-color;
                    border-radius: 10px;
                    width: 24%;
                    box-shadow: 10px 0px 9px 1px rgba(0, 0, 0, 0.1);

                    .contents-header h3 {
                        margin: 0px 0px 20px 0px;
                        padding: 0;
                    }

                    .contents-body {
                        height: 230px;
                        overflow-y: scroll;

                        ul li {
                            padding: 0px 0px 10px 0px;
                        }
                    }
                }
            }
        }
    }

    .my-content-area-form {
        height: 60vh;

        thead td {
            position: sticky;
            /* make the table heads sticky */
            top: -1px;
        }

        .location-form {
            padding: 20px 30px;
            display: grid;
            gap: 24px 80px;

            .input-text-area {
                grid-column: 1 / span 2;
            }
        }

        .my-content-permissions-list {
            padding: 5px 40px;
            height: 46vh;
            overflow-y: scroll;

            h3 {
                font-size: 24px;
                color: #1F1F1F;
                font-weight: 500;
                margin-top: 0px;
            }

            .permission-list {
                .permission-lis-session {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }
    }

    .my-content-area-form-1 {
        height: 72vh;
        overflow-y: scroll;

        thead td {
            position: sticky;
            /* make the table heads sticky */
            top: -1px;
        }
    }
}