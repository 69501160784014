.app {
  margin: 0;
  padding: 0;
}

.layout {
  display: flex;
  height: 100vh;

  .side-bar-session {
    width: 17%;
    display: flex;
    flex-direction: column;

    .logo-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8vh;
    }
  }

  .content-session {
    width: 83%;

    display: flex;
    flex-direction: column;

    .main-container {
      border-radius: 8px;
      background-color: $backend-blue;
      padding: 12px;
      height: 92vh;

      display: flex;
      flex-direction: column;
      gap: 12px;

      .content-area {
        border-radius: 8px;
        background-color: $white-color;
        // padding-bottom: 20px;

        max-height: 100%;
        overflow-y: scroll;
      }

      .errorMsg {
        color: $danger-text;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
      }
    }

  }
}

.text-danger {
  color: $danger-text;
}

.text-success {
  color: $success-text;
}

.message {
  margin-left: 20px;
}

.success-tag {
  background: #F7FAFC;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: #198437;
  font-size: 13px;
  width: 100px;
  align-items: center;
  padding: 10px;
}

.warning-tag {
  background: #fefef7;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: #847f19;
  font-size: 13px;
  width: 100px;
  align-items: center;
  padding: 10px;
}

.danger-tag {
  background: #ffd7d973;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: #E8212e;
  font-size: 13px;
  width: 100px;
  align-items: center;
  padding: 10px;
}

.manus {
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    width: 159px;
    margin-top: -20px;
    // left: 1278px !important;
    border: #CACACA solid 1px;
  }
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border: #CACACA solid 1px;
  height: auto;
  max-height: 250px !important;
}


#basic-menuundefined {
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    width: 159px;
    // top: 91px !important;
    left: 1339px !important;
    border: #CACACA solid 1px;
  }
}

.text-center {
  display: flex;
  justify-content: center !important;
}

@media only screen and (max-width:760px) and (min-width:450px) {
  .layout {
    .side-bar-session {
      width: 0;
    }

    .content-session {
      width: 100%;
    }
  }
}

@media only screen and (max-width:450px) {
  .layout {
    .side-bar-session {
      width: 0;
    }

    .content-session {
      width: 100%;
    }
  }
}