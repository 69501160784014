// CSS for both summary and Info page

.main-container {
  .content-area {
    .product-summary-wrap {
      background-color: #F1F5F8 !important;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      gap: 20px;

      .summary-title {
        margin: 0 30px 10px;
        font-size: 16px;
        font-weight: 600;
        color: $black-color;
      }

      .product-summary {
        height: max-content;
        padding: 20px 0 0;
        background-color: $white-color;
        border-radius: 3px;
      }

      .payment-info {
        height: max-content;
        padding: 20px 0 0;
        background-color: $white-color;
        border-radius: 3px;

        .payment-info-form {
          padding: 10px 30px;
          display: flex;
          flex-direction: column;
          gap: 15px;

          .single-row {
            display: grid;
            grid-template-columns: 47% 47%;
            justify-content: space-between;

            .error-message {
              padding-top: 4px;
              color: $danger-text;
              font-size: 12px;
              height: 12px;
            }

            .input-symbol {
              left: 17px;
              top: 49.5px;
            }

            .input-inner-symbol {
              input {
                padding: 7px 30px !important;
              }
            }
          }
        }
      }

      .price-summary {
        height: max-content;
        padding: 20px 0 0;
        background-color: $white-color;
        border-radius: 3px;

        .price-details {
          padding: 20px 30px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .single-detail {
            display: flex;
            justify-content: space-between;

            span {
              font-size: 14px;
              font-weight: 500;
              color: $black-color;
            }

            .wallet-price {
              color: $green-color;
            }
          }

          .total {
            margin: 10px 0;
            padding: 30px 0 0;
            border-top: 0.5px dashed $border-color;

            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}