.rent-popup {
    margin: 20px 50px;

    p {
        margin: 0;
    }

    .btn-container {
        margin-top: 10%;
        display: flex;
        justify-content: center;
        gap: 50px;

        .btn {
            width: max-content;

            button {
                min-width: 0;
                width: 70px;
            }

            &:nth-child(1) {
                button {
                    border: none;

                    &:hover {
                        color: #1F1F1F;
                        background-color: #0000001e;
                    }
                }
            }
        }
    }
}

// Selecting device
.device-wrap {
    width: 65vw;

    .all-devices {
        max-height: 50vh;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        gap: 25px;    
        
        &::-webkit-scrollbar{
            width: 5px;
        }

    }

    .device-container {
        display: grid;
        grid-template-columns: 2fr repeat(3, 1fr) 0.5fr;
        gap: 20px;
        align-items: center;

        .add-container {
            margin-top: 30px;
            font-size: 16px;
            font-weight: 600;
            color: #1A5EEC;
            cursor: pointer;
        }

        .remove-container {
            margin-top: 30px;
            font-size: 16px;
            font-weight: 600;
            color: red;
            cursor: pointer;
        }
    }

    .btn-container {
        margin-top: 20px;
        margin-right: 11%;
        display: flex;
        justify-content: flex-end;
        gap: 50px;

        .btn {
            width: max-content;

            button {
                min-width: 0;
                width: 70px;
            }

            &:nth-child(1) {
                button {
                    border: none;

                    &:hover {
                        color: #1F1F1F;
                        background-color: #0000001e;
                    }
                }
            }
        }
    }

}