.lineChart-wrap {
  box-shadow: #1F1F1F;
  border-radius: 10px;
  background-color:$white-color;

  .lineChart-header {
    padding: 15px 15px 0 15px;

    .lineChart-right {


      h2 {
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
      }

      p {
        margin: 0;
        font-size: 14px;
        padding-top: 10px;
        color: $gray-color;
        font-weight: 200 !important;
      }
    }

  }

  .lineChart-table {
    width: 100%;
    height: 300px;
  }
}