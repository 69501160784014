.catalogue-form-wrap {
    height: 75vh;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 30% auto;
    padding: 20px;
    gap: 1rem;

    .profile-upload {}

    .catalogue-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: 1px solid #1F1F1F4e;
        border-radius: 8px;

        h1 {
            font-size: 16px;
            font-weight: 600;

            .subtitle {
                font-size: 13px;
                font-weight: 500;
                color: $gray-color;
            }
        }

        .catalogue-form-top {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            border-bottom: 1px solid #1F1F1F4e;

            .single-row {
                display: grid;
                grid-template-columns: 47% 47%;
                justify-content: space-between;
            }

            .single-row-3 {
                display: grid;
                grid-template-columns: 30% 30% 30%;
                justify-content: space-between;
            }
        }

        .catalogue-form-bottom {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .single-row {
                display: grid;
                grid-template-columns: 23% 23% 23% 23%;
                justify-content: space-between;
                padding-bottom: 20px;
                border-bottom: solid 1px $light-border-color;

                .currency-name {
                    display: flex;
                    gap: 5px;
                    border: solid 1px $gray-light;
                    border-radius: 3px;
                    margin-top: 30px;
                    align-items: center;
                    padding: 10px;

                    .currency-label {
                        color: $gray-color;
                        font-size: 13px;
                    }
                }

                .input-inner-symbol{

                    .input-symbol{
                        top: 49px !important;
                        left: 15px !important;
                    }

                    .input-box-symbol{
                        padding: 7px 30px !important;
                    }
                }
            }
        }
    }
}

// Form view without images

.no-image-view{
    grid-template-columns: 1fr;
}