.license-view {
  min-width: 830px;

  .view-header {
    padding: 15px 0 20px;
    // border-bottom: 1px solid #4b4b4b4e;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      color: $black-color;

      span {
        font-weight: 600;
      }
    }

    p {
      margin: 10px 0;
      font-size: 14px;
      font-weight: 500;
      color: $gray-color;
    }
  }

  .each-content {
    display: grid;
    grid-template-columns: 300px auto;

    h3 {
      // margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: $black-color;

      span {
        display: flex;
        justify-content: space-evenly;
        color: $gray-color;
      }
    }

    p {
      // margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: $black-color;
    }
  }

  .view-content {
    padding: 20px 0;
    // border-bottom: 1px solid #4b4b4b4e;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .plan_details {
      margin: 20px 0 15px;
      .each-content {
        padding-bottom: 12px;
      }

      h1 {
        margin: 0 0 20px;
        font-size: 16px;
        font-weight: 600;
        color: $gray-color;
      }
    }

  }

  .bill-total {
    margin: 20px 0;

    .each-content{
      h3{
        font-size: 16px;
      }
    }
  }
}