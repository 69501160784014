.not-found {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
    height: 100vh;

    .not-found-text {
        h1 {
            font-size: 24px;
            font-weight: 500;
        }
        h3 {
            font-size: 18px;
            font-weight: 500;
        }
        .not-found-button {
            width: 31%;
        }
    }
}