.model-box {
    display: flex;
    flex-direction: column;
    .model-header-title {
        .model-title {
            font-weight: 600;
            font-size: 20px;
            text-align: center;
        }
        .model-body-content {
            font-weight: 400;
            font-size: 16px;
            text-align: center;
        }
    }
    .model-header-title-rental {
        padding-bottom: 20px;
    }
    .model-delete-buttons {
        display: flex;
        justify-content: center;
        gap: 25px;
        .btn {
            width: 15%;
        }
    }
}