.dashboard-content {
  border-radius: 8px;
  background-color: $backend-blue;
  height: 92vh;
  overflow-y: scroll;
  overflow-x: hidden;


  .count-cards {
    padding: 12px 12px 10px;
    background-color: $backend-blue;
    position: sticky;
    z-index: 10;
    top: 0;


    h1 {
      line-height: 46px;
      font-size: 22px;
      font-weight: 600;
    }
  }

  .barChart-component {
    padding: 12px 12px 10px;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 20px;
  }

  .market-container {
    padding: 12px 12px 10px;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
  }
}

@media only screen and (max-width:1000px) and (min-width:450px) {
  .dashboard-content {
    .count-cards h1 {
      font-size: 20px;
    }

    .barChart-component {
      display: flex;
      flex-direction: column;
    }

    .market-container {
      display: flex;
      flex-direction: column;
    }
  }

}

@media only screen and (max-width:450px) {
  .dashboard-content {
    .count-cards h1 {
      font-size: 16px;
    }

    .barChart-component {
      display: flex;
      flex-direction: column;
    }

    .market-container {
      display: flex;
      flex-direction: column;
    }
  }
}