.content-area {
    .addOn-form {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 30px;

        .single-row{
            display: grid;
            grid-template-columns: 47% 47%;
            justify-content: space-between;
        }

        .pricing {
            width: 55%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            h2 {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                color: $black-color;

                span{
                    font-weight: 500;
                }
            }

            .add-ons {
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid $gray-color;
                background-color: $white-color;
                border-spacing: 0 !important;

                tr {
                    display: grid;
                    grid-template-columns: 1.5fr repeat(3, 1fr);

                    th {
                        padding: 5% 10%;
                        display: flex;
                        align-items: center;
                        text-align: left;

                        margin: 0;
                        border-right: 1px solid $gray-color;
                        border-bottom: 1px solid $gray-color;

                        font-size: 14px;
                        color: $black-color;

                        span {
                            font-weight: 500;
                            font-size: 20px;
                            color: $success-text;
                        }
                    }

                    td {
                        padding: 7% 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        margin: 0;
                        border-right: 1px solid $gray-color;
                        border-bottom: 1px solid $gray-color;

                        font-size: 14px;
                        color: $success-button-background-color;
                        background-color: $success-table-header-background-color;

                        .input-container {
                            width: 50%;

                            .input-black {
                                gap: 0;
                            }
                        }
                    }

                    .last-data {
                        border-right: 0;
                    }

                    .bottom-data {
                        border-bottom: 0;
                        display: flex;
                        gap: 5px;
                    }
                }
            
                .input-inner-symbol {
                    .input-symbol {
                        top: 47%;
                        left: 18%;
                    }

                    .input-box-symbol {
                        text-align: center;
                        padding: 0 0 0 30% !important;
                    }
                }
            }
        }

    }
}