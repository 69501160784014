.table-header-right{
  display: flex;
  align-items: center;

  .csv-button-wrap{
      padding: 0px 8px;
  }
}

.export-button-text{
  display: flex;
  align-items: center;
  gap: 5px;

  span{
      font-size: 12px;
      color: $label-color;
      font-weight: 500;
  }
}