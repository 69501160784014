.content-area {
    .subscription-edit {
        height: 75vh;
        overflow-y: scroll;

        display: flex;
        flex-direction: column;
        gap: 30px;
        background-color: $backend-blue;

        .subscription-edit-form {
            background-color: #FFFFFF;
            padding: 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            h1 {
                font-size: 16px;
                color: $black-color;
            }

            .single-row {
                display: grid;
                grid-template-columns: 47% 47%;
                justify-content: space-between;

                .input-with-price {
                    display: grid;
                    grid-template-columns: 60% auto;
                    gap: 20px;
                    align-items: center;

                    p {
                        margin: 30px 0 0 0;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }

        .addOn-summary{
            height: max-content;
            display: flex;
            justify-content: space-between;

            .title{
                margin: 8px 0;
                font-size: 16px;
                font-weight: 500;
                color: $black-color;
            }

            .addOn{
                width: 55%;
                padding: 20px;
                background-color: $white-color;

                p{
                    float: right;
                    color: $success-table-header-color;
                    font-size: 15px;
                }

                .input-with-price{
                    display: grid;
                    grid-template-columns: 65% auto;
                    align-items: center;
                    gap: 20px;
    
                    p{
                        margin-top: 40px;
                        font-size: 14px;
                        font-weight: 500;
                        color: $black-color;
                    }
                }
            }

            .summary-wrapper{
                width: 43%;
                padding: 20px;
                background-color: $white-color;

                .summary-details{
                    
                    .summary-detail{
                        margin-top: 10px;
                        display: grid;
                        grid-template-columns: 80px auto;
                        gap: 30px;

                        h3{
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: $black-color;
                        }

                        p{
                            margin: 0;
                            font-size: 14px;
                            font-weight: 600;
                            color: $black-color;
                        }
                    }

                    .total-price{
                        margin-top: 15px;
                        padding-top: 15px;
                        border-top: 1px dashed $gray-light;
                    }
                }
            }
        }
    }
}