.map-wrap {
  position: relative;
  width: inherit;
  box-shadow: #1F1F1F;
  border-radius: 10px;
  background-color: $white-color;

  .map-controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 30px;
    right: 10px;
    gap: 10px; 

    button {
      font-weight: bold;
      border: none;
    }
  }

  .map-left {
    padding: 15px;

    h2 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }
  .map-chart {
    width: 90%;
  }
}
