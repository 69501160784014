.payment-pending{
  color: $pending-color;
  background-color: $warning-button-background-light-color;
  padding: 7px 15px;
  border-radius: 3px;
  text-transform: capitalize;
}

.payment-updated{
  color: $green-color;
  background-color: $success-table-header-background-color;
  padding: 7px 15px;
  text-transform: capitalize;
  border-radius: 3px;
}

.cms-tag {
  color: $green-color;
  background-color: $success-table-header-background-color;
  padding: 7px 15px;
  text-transform: capitalize;
  border-radius: 3px;
}

.admin-tag {
  color: $blue-color;
  background-color: $info-text-hover-background-color;
  padding: 7px 15px;
  border-radius: 3px;
  text-transform: capitalize;
}

.distributor-tag {
  color: $pending-color;
  background-color: $warning-button-background-light-color;
  padding: 7px 15px;
  border-radius: 3px;
  text-transform: capitalize;
}

.cmsServer-tag {
  color: $green-color;
  background-color: $success-table-header-background-color;
  padding: 7px 15px;
  text-transform: capitalize;
  border-radius: 3px;
}
