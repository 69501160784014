.ota-update-popup {
  display: flex;
  align-items: center;
  gap: 10px;

  .device-count {
      display: flex;
      align-items: center;
      gap: 10px;

      padding-right: 10px;
      border-right: 2px solid #FFFFFF50;

      span {
          display: flex;
          cursor: pointer;
      }

      p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
      }
  }

  span {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
  }
}