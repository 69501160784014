.model-main-container {
  width: 480px;

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 15px;

    max-height: 70vh;
    overflow-y: scroll;

    .file-upload {
      display: flex;
      align-items: center;
      justify-content: center;

      min-height: 150px;
      border: 0.25px solid #cacaca;
      border-radius: 10px;
      background-color: #F9FAFB;

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        color: $black-color;

        span {
          color: $success-text;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .file-upload-image {
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
          height: 50px;
          width: 50px;
        }
      }
    }

    .view-file {
      display: flex;
      gap: 15px;
      align-items: center;
      width: 100%;
      height: 40px;
      border: 0.25px solid #cacaca;
      border-radius: 5px;

      p {
        margin: 0px;
      }

      img {
        height: 35px;
        width: 35px;
      }
    }

    .replace-version-button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: $black-color;
      }

      .toggle-button {
        .switch {
          position: relative;
          display: inline-block;
          width: 55px;
          height: 28px;

          input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: .4s;
            border-radius: 34px;

            &:before {
              position: absolute;
              content: "";
              height: 20px;
              width: 20px;
              left: 4px;
              bottom: 4px;
              background-color: white;
              transition: .4s;
              border-radius: 50%;
            }
          }

          input:checked+.slider {
            background-color: #2196F3;
          }

          input:checked+.slider:before {
            transform: translateX(26px);
          }

          &.round {
            .slider {
              border-radius: 34px;

              &:before {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .Form-button {
      padding-top: 15px;
      justify-content: flex-end;
      display: flex;
      gap: 20px;
    }
  }
}