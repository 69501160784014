.expend-subscription-form {
    width: 25vw;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .button-group {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
}