.main-container {
  .content-area {
    .master-wrap {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      .master-header-button {
        position: sticky;
        bottom: 0;
        z-index: 2;
        background-color: $white-color;
        border-radius: 8px;

        padding: 20px 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        display: flex;
        gap: 15px;
      }

      .master-container {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .sub-feature-warp {
          padding: 0 25px;
          display: grid;
          grid-template-columns: 1fr 80px;

          border-bottom: 1px solid $border-color;

          .sub-feature-container {
            padding: 0 0 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .multi-field-row {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 20px;
            }

            .three-field-row {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 20px;
            }

            .input-black {

              .input-box {
                resize: none;
              }
            }
          }

          .sub-feature-add {
            padding: 0 0 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            .add-button {
              font-size: 14px;
              font-weight: 600;
              color: $success-button-background-color;
              cursor: pointer;
            }

            .remove-button {
              font-size: 14px;
              font-weight: 600;
              color: $danger-text;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}