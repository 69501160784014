.content-area {
    .device-order-form {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        h1 {
            font-size: 16px;
            color: $black-color;
        }

        .device-order-form-header {
            display: flex;
            justify-content: space-between;
            h1 {
                font-size: 16px;
                color: $black-color;
            }

        }

        .single-row {
            display: grid;
            grid-template-columns: 47% 47%;
            justify-content: space-between;

            .input-with-price{
                display: grid;
                grid-template-columns: auto max-content;
                gap: 20px;
                align-items: center;

                p{
                    margin: 30px 0 0 0;
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        .rental-devices-form {
            .rental-device-row {
                display: grid;
                grid-template-columns: 22% 22% 22% 20% 10%;
                gap: 10px;
                align-items: center;
                padding: 20px 5px;
                border-bottom: solid 0.5px #ccc;
        
                .input-inner-symbol {
                    input {
                        padding: 8px 37px !important;
                    }
        
                    .input-symbol {
                        top: 71%;
                        transform: translate(70%, -50%);
                    }
                }
        
                .add-button {
                    color: $success-text;
                    cursor: pointer;
                    margin-top: 30px;
                }
        
                .delete-button {
                    cursor: pointer;
                    color: $danger-text;
                    margin-top: 30px;
                    width: 10px;
                }
            }
        }
    }
}
.add-button {
    cursor: pointer;
    color: $success-text;
    font-weight: bold;
}

.edit-button-serial {
    display: flex;
    width: 100%;
    justify-content: space-between;

    div .action-button {
        width: auto !important;
    }
    
    .edit-button {
        width: 16px;
    }
}

.serial-numbers-list {
    max-height: 67vh;
    overflow-y: scroll;
}