.popup-wrap {
  min-width: 450px;
  max-height: 360px;
  overflow-y: scroll;

  .popup-header {
    display: flex;
    align-items: center;
    gap: 20px;

    .header-details {
      display: flex;
      align-items: center;
      gap: 5px;

      h2 {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: $black-color;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        color: $black-color;
      }
    }
  }

  .popup-container {
    margin-top: 10px;
    padding: 15px 20px;
    border: 0.5px solid $border-color;
    border-radius: 3px;

    .payment-message {
      font-size: 14px;
      color: $text-gray-color;
    }
    .payment-label {
      font-size: 16px;
    }

    .plan-wrap {

      .current-plan {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          color: $black-color;

          &:last-child {
            padding: 0 10px;
            font-size: 10px;
            color: $success-text;
            background-color: $success-table-header-background-color;
          }
        }
      }

      .available-plans {}

    }

    .input-with-price {
      margin: 0 0 5px;
      display: grid;
      grid-template-columns: auto max-content;
      gap: 20px;
      align-items: center;

      p {
        margin: 30px 0 0 0;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .alert-message {
      margin: 0 0 15px;
      font-size: 12px;
      font-weight: 500;
      color: $danger-text;
      opacity: var(--opacity, '1');
    }

    .plan-btn-wrap {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }
  }
}