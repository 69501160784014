.main-container {
  .content-area {
    .controller-wrap {
      height: 100%;
      position: relative;
      display: inline-flex;
      flex-direction: column;
      padding: 24px 12px;

      table {
        border: 0.1px solid #CACACA5e;
        border-collapse: collapse;
        table-layout: auto;

        .sticky-header {
          position: sticky;
          top: 0px;
          z-index: 2;
        }

        tbody,
        thead {
          display: block;
          width: 100%;

          tr {
            display: flex;

            th {
              font-size: 14px;
              font-weight: 600;
              background-color: $gray-hover-color;
              color: $gray-color;
              text-align: left;
            }

            th,
            td {
              min-width: 250px;
              flex: 1;
              border: 0.1px solid #CACACA5e;
              border-right: 0px;
              border-top: 0px;
              border-collapse: collapse;
              padding: 16px 8px;

              &:first-child {
                background-color: $white-color;
                position: sticky;
                left: 0;
                z-index: 1;
              }

              input {
                border: none;
                outline: none;

              }

              /* Chrome, Safari, Edge, Opera */
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              // Style for css border
              &:first-child {
                border-left: 0px;
              }
            }

            th {
              td {
                &:first-child {
                  background-color: $gray-hover-color;
                  position: sticky;
                  left: 0;
                  z-index: 1;
                }
              }
            }

            .feature-header {
              font-size: 14px;
              font-weight: 600;
              color: $black-color;
            }

            .feature-data {
              min-width: 250px;
              font-size: 14px;
              font-weight: 500;
              color: $black-color;

              display: flex;
              justify-content: space-between;

              input {
                width: 100%;
              }

              .feature-data-dropdown {
                display: flex;
                gap: 10px;

                select {
                  border: none;
                  background-color: transparent;
                  cursor: pointer;
                }
              }
            }

            // Style for css border
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }

      .controller-buttons {
        position: sticky;
        bottom: 0;
          left: 0;
        z-index: 3;
        
        width: 81.5vw;
        background-color: $white-color;
        padding: 10px 18px 20px 25px;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
        
        .btn {
          flex: 0 0 15%;
        }
      }
    }
  }
}