.main-container {
  .plans-form-wrap {
    height: 75vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .plans-details {
      padding: 30px;
      border-radius: 8px;
      background-color: $white-color;

      display: flex;
      flex-direction: column;
      gap: 30px;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .single-row {
        display: grid;
        grid-template-columns: 47% 47%;
        justify-content: space-between;
        align-items: flex-end;


        .input-groups {
          display: grid;
          grid-template-columns: auto max-content max-content;
          gap: 15px;
          align-items: flex-end;
        }
      }
    }

    .features-pricing {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;

      .features {
        padding: 30px;
        border-radius: 8px;
        background-color: $white-color;

        max-height: 340px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h1 {
          font-size: 16px;
          color: $black-color;
        }

        .each-feature {
          display: grid;
          grid-template-columns: 87% auto;
          gap: 10px;
          align-items: center;

          .add-feature {
            font-size: 14px;
            font-weight: 600;
            color: #1A5EEC;
            cursor: pointer;
          }

          .remove-feature {
            font-size: 14px;
            font-weight: 600;
            color: red;
            cursor: pointer;
          }
        }
      }

      .pricing {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
          margin: 0;
          font-size: 16px;
          font-weight: 600;
          color: $black-color;
        }

        .device,
        .add-ons {
          border-radius: 8px;
          overflow: hidden;
          border: 1px solid $gray-color;
          background-color: $white-color;
          border-spacing: 0 !important;

          tr {
            display: grid;
            grid-template-columns: 1.5fr repeat(3, 1fr);

            th {
              padding: 5% 10%;
              display: flex;
              align-items: center;
              text-align: left;

              margin: 0;
              border-right: 1px solid $gray-color;
              border-bottom: 1px solid $gray-color;

              font-size: 14px;
              color: $black-color;

              span {
                font-weight: 500;
                font-size: 20px;
                color: $success-text;
              }
            }

            td {
              padding: 7% 0;
              display: flex;
              align-items: center;
              justify-content: center;

              margin: 0;
              border-right: 1px solid $gray-color;
              border-bottom: 1px solid $gray-color;

              font-size: 14px;
              color: $success-button-background-color;
              background-color: $success-table-header-background-color;

              .input-container {
                width: 50%;

                .input-black {
                  gap: 0;
                }
              }
            }

            .last-data {
              border-right: 0;
            }

            .bottom-data {
              border-bottom: 0;
              display: flex;
              gap: 5px;
            }
          }
        }

        .device {
          .input-inner-symbol {
            .input-symbol {
              top: 47%;
              left: 18%;
            }

            .input-box-symbol {
              text-align: center;
              padding: 0 0 0 30% !important;
            }
          }
        }
      }
    }

    .features-pricing-license {
      display: grid;
      // grid-template-columns: 1fr 1fr;
      gap: 15px;

      .features {
        padding: 30px;
        border-radius: 8px;
        background-color: $white-color;

        max-height: 340px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h1 {
          font-size: 16px;
          color: $black-color;
        }

        .each-feature {
          display: grid;
          grid-template-columns: 87% auto;
          gap: 10px;
          align-items: center;

          .add-feature {
            font-size: 14px;
            font-weight: 600;
            color: #1A5EEC;
            cursor: pointer;
          }

          .remove-feature {
            font-size: 14px;
            font-weight: 600;
            color: red;
            cursor: pointer;
          }
        }
      }

      .pricing {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
          margin: 0;
          font-size: 16px;
          font-weight: 600;
          color: $black-color;
        }

        .device,
        .add-ons {
          border-radius: 8px;
          overflow: hidden;
          border: 1px solid $gray-color;
          background-color: $white-color;
          border-spacing: 0 !important;

          tr {
            display: grid;
            grid-template-columns: 1.5fr repeat(2, 1fr);

            th {
              padding: 5% 10%;
              display: flex;
              align-items: center;
              text-align: left;

              margin: 0;
              border-right: 1px solid $gray-color;
              border-bottom: 1px solid $gray-color;

              font-size: 14px;
              color: $black-color;

              span {
                font-weight: 500;
                font-size: 20px;
                color: $success-text;
              }
            }

            td {
              padding: 7% 0;
              display: flex;
              align-items: center;
              justify-content: center;

              margin: 0;
              border-right: 1px solid $gray-color;
              border-bottom: 1px solid $gray-color;

              font-size: 14px;
              color: $success-button-background-color;
              background-color: $success-table-header-background-color;

              .input-container {
                width: 50%;

                .input-black {
                  gap: 0;
                }
              }
            }

            .last-data {
              border-right: 0;
            }

            .bottom-data {
              border-bottom: 0;
              display: flex;
              gap: 5px;
            }
          }
        }

        .device {
          .input-inner-symbol {
            .input-symbol {
              top: 47%;
              left: 18%;
            }

            .input-box-symbol {
              text-align: center;
              padding: 0 0 0 30% !important;
            }
          }
        }
      }
    }
  }
}