::-webkit-scrollbar {
  width: 1px;
  height: 2px;
  display: block;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 5px;
  display: block;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  display: block;
}