.upload-container {
    .image-slider-wrap {
        border-radius: 8px;
        border: 1px solid #1F1F1F4e;

        .photo-upload {

            .upload-click {
                height: 200px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 5px;

                svg {
                    height: auto;
                }

                p {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 500;

                    span {
                        color: $success-button-background-color;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }

        .slider {
            width: 100%;
            height: 200px;

            .slider-image {
                height: inherit;
                width: inherit;
                object-fit: contain;
            }
        }
    }

    .selected-images {
        margin: 4% 0;
        display: flex;
        justify-content: center;
        gap: 10px;

        .image-container {
            position: relative;
            border-radius: 4px;
            border: 1px solid #1F1F1F4e;

            width: 20%;
            height: 60px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            .upload-click {
                width: 100%;
                height: 100%;

                margin: 0;
                text-align: center;
                cursor: pointer;
            }

            .deleteIcon{
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background: linear-gradient(#ffffff, #ffffff2e);
                position: absolute;
                top: 5%;
                right: 5%;

                display: flex;
                align-items: center;
                justify-content: center;

                svg{
                    margin: 0;
                    height: auto;
                }
            }

            .each-image {
                object-fit: cover;
            }

            p{
                display: none;
            }
        }
    }
}