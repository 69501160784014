.device-name-wrap {
    display: flex;
    align-items: center;
    gap: 5px;

    .device-open-icon{
        display: flex;
        cursor: pointer;
    }
}

.device-details{
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .each-detail{
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        h3{
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: $black-color;
        }
        p{
            margin: 0;
            font-size: 14px;
            font-weight: 700;
            color: $black-color;
        }
    }
}