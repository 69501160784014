.pieChart-wrap {
  width: inherit;
  box-shadow: #1F1F1F;
  border-radius: 10px;
  background-color: $white-color;

  .pieChart-left {
    padding: 15px;

    h2 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }

    p {
      font-size: 14px;
      margin: 0;
      color: $gray-color;
      font-weight: 200 !important;
    }
  }

  .pieChart-table {
    display: grid;
    grid-template-columns: 60% 30%;
    align-items: center;
    padding: 0 0 30px 25px;

    .pieChart-container {
      position: relative;
      width: inherit;
      height: 250px;

      .text-container {
        position: absolute;
        top: 52%;
        left: 52%;
        transform: translate(-50%, -50%);
        z-index: 2;

        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;

        span {
          font-size: 14px;
          font-weight: 500;
          color: $gray-color;

          &:last-child {
            font-size: 28px;
            font-weight: 600;
            color: $label-color;
          }
        }
      }
    }
  }

  .legend {
    display: flex;
    flex-direction: column;
    gap: 20px;

    max-height: 200px;
    overflow-y: scroll;

    .each-legend {
      font-size: 14px;
      display: flex;
      align-items: center;

      .marker {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      p {
        margin: 0;
        margin-left: 8px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .each-items {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        margin: 0;
        margin-left: 8px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .each-items-value {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        margin: 0;
        margin-left: 8px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
    }
  }
}

.recharts-layer:active,
.recharts-layer:focus {
  outline: none !important;
}

.recharts-layer path:active,
.recharts-layer path:focus {
  outline: none !important;
}