.content-area {
    .summary-wrap {
        height: 75vh;
        overflow-y: scroll;
        background-color: #F1F5F8 !important;

        display: flex;
        flex-direction: column;
        gap: 20px;

        .summary-container {
            background-color: #FFFFFF;
            border: 1px solid #1C75BC;
            border-radius: 8px;
            padding-bottom: 10px;

            .summary-header {
                padding: 15px 30px 15px 20px;
                display: flex;
                justify-content: space-between;

                h3 {
                    margin: 0;
                    font-size: 16px;
                    color: $black-color;

                    span {
                        margin-left: 10px;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .edit-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;

                    span {
                        font-size: 14px;
                        color: #9747FF;
                    }
                }
            }
            .summery-sub {
                padding: 10px 20px;
            }

            .total-wrap {
                padding: 15px 15.5% 10px 1.5%;
                border-top: 1px solid #CACACA;

                display: flex;
                justify-content: space-between;

                p {
                    margin: 0;
                    font-size: 14px;
                    color: $black-color;
                }

                span {
                    font-size: 20px;
                    font-weight: 500;
                }
            }

            .price-summary-wrap {
                padding: 20px;

                h1 {
                    margin: 0;
                    font-size: 16px;
                    color: $black-color;
                }

                .price-summary {
                    margin: 2% 0;

                    .price {
                        border: none;
                        margin-bottom: 20px;
                        padding: 0 5% 0 0;
                        display: flex;
                        justify-content: space-between;

                        h2 {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: $black-color;
                        }

                        p {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: $label-color;
                        }
                    }
                    p {
                        font-size: 14px;
                        color: $text-gray-color;
                    }
                }
            }
            .price {
                border-top: solid 0.6px #CACACA;
                padding: 2% 8% 2% 3%;
                display: flex;
                justify-content: space-between;

                h2 {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: $black-color;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: $label-color;
                }
            }
        }
    }
}