.table {
    border-collapse: collapse;
    width: 100%;
    position: relative;

    thead tr {
        background: #F7FBFE;
    }

    thead tr td,
    tbody tr td {
        padding: 15px 0px 15px 20px;
        font-weight: 500;
        font-size: 15px;
        // border-top: 0.1px solid #ccc;
    }

    thead tr td {
        position: relative;
        color: #1C75BC;
        background: #F7FEF8;
        z-index: 9;

        div {
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            cursor: pointer;

            .sort-button {
                width: 15px;
            }
        }
    }

    tbody {
        tr td {
            color: #1F1F1F;
            border-top: 0.1px solid #ccc;

            // .danger-tag, .success-tag{
            //     margin-top: 30px;
            // }

            .action-button {
                width: 15px;
                cursor: pointer;
            }

            .coll-tag-1 {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .coll-tag {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .tag {
                    padding: 10px;
                    background: #F7FEF8;
                    color: #30A84B;
                }
            }

            .coll-tag-avatar {
                display: flex;
                gap: 10px;
                align-items: center;

                img {
                    width: 35px;
                    border-radius: 50px;
                }

                .profile_name {
                    line-height: 0;

                    p {
                        color: #4B4B4B;
                        font-size: 14px;
                        margin: 20px 0px 0px 0px;
                    }
                }
            }
        }

        .file-size-wrap {
            padding-left: 0;
            
            .file-size {
                width: 100px;
                text-align: right;
            }
        }

    }

    tbody tr .array-col {
        padding: 5px 0px 4px 20px;

        p {
            line-height: 0.3;
            color: #7c7c7c;
            font-weight: 100;

            b {
                color: #1F1F1F;
            }
        }
    }

    tbody tr:hover {
        background: $gray-hover-color;
    }

}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    box-shadow: 0px 1px 1px -3px rgb(180 180 180 / 20%), 0px 2px 3px 1px rgb(172 170 170 / 14%), 0px 1px 1px 1px rgb(195 193 193 / 12%) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root a {
    color: #1F1F1F !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.border {
    border-left: solid #ccc 0.5px;
    border-right: solid #ccc 0.5px;
    border-bottom: solid #ccc 0.5px;
}

.no-data-found {
    text-align: center;
    font-size: 19px;
}

.action-menu {
    background: $danger-background !important;
    color: $danger-text !important;
    width: 100%;
}
.muiltr-10gei56, .css-10gei56 {
    display: none !important;
}

.action-button {
    width: 15px !important;
    cursor: pointer;
}

.text-dropdown {
    width: 110px !important;
    cursor: pointer;     
}

.pending, .active {
    padding: 2px 3px;
    color: $white-color;
    border-radius: 5px;
    text-transform: capitalize;
}
.pending {
    background-color: $yellow-color;
}
.active {
    background-color: $success-table-header-color;
}